import { Action } from '@ngrx/store';
import { MenuItem, NavtreeItem, UserState } from 'app/shared/models';

export enum Type {
  LOAD_NAVTREE = '[NAVTREE] LOAD_NAVTREE',
  LOAD_NAVTREE_SUCCESS = '[NAVTREE] LOAD_NAVTREE_SUCCESS',
  LOAD_NAVTREE_CANCELED = '[NAVTREE] LOAD_NAVTREE_CANCELED',
  UPDATE_VISIBILITY = '[NAVTREE] UPDATE_VISIBILITY',
  UPDATE_MISSING_VISIBILITY = '[NAVTREE] UPDATE_MISSING_VISIBILITY',
  UPDATE_PARAMETER_MENU_VISIBILITY = 'UPDATE_PARAMETER_MENU_VISIBILITY',
  TOGGLE_SIDEBAR = '[NAVTREE] TOGGLE_SIDEBAR',
  UPDATE_ISHANDSET = '[NAVTREE] UPDATE_ISHANDSET',
  UPDATE_ISTABLET = '[NAVTREE] UPDATE_ISTABLET'
}

export class LoadNavtree implements Action {
  readonly type = Type.LOAD_NAVTREE;
}
export class LoadNavtreeCanceled implements Action {
  readonly type = Type.LOAD_NAVTREE_CANCELED;
}
export class LoadNavtreeSuccess implements Action {

  readonly type = Type.LOAD_NAVTREE_SUCCESS;
  constructor(public payload: NavtreeItem[]) { }
}

export class UpdateVisibility implements Action {

  readonly type = Type.UPDATE_VISIBILITY;
  constructor(public params: UserState, public items: MenuItem[]) { }
}

export class UpdateMissingVisibility implements Action {

  readonly type = Type.UPDATE_MISSING_VISIBILITY;
  constructor(public payload: MenuItem[]) { }
}

export class UpdateParameterMenuVisibility implements Action {

  readonly type = Type.UPDATE_PARAMETER_MENU_VISIBILITY;
  constructor(public params: UserState, public menuItems: MenuItem[]) { }
}

export class UpdateIsHandset implements Action {
  readonly type = Type.UPDATE_ISHANDSET;
  constructor(public payload: boolean) { }
}

export class UpdateIsTablet implements Action {
  readonly type = Type.UPDATE_ISTABLET;
  constructor(public payload: boolean) { }
}

export class ToggleSidebar implements Action {
  readonly type = Type.TOGGLE_SIDEBAR;
  constructor(public payload: boolean) { }
}


export type Actions = LoadNavtree | LoadNavtreeSuccess | LoadNavtreeCanceled
  | UpdateVisibility | UpdateMissingVisibility | UpdateParameterMenuVisibility | UpdateIsHandset | UpdateIsTablet | ToggleSidebar;
