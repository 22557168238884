import { AutoReportMode, Language, ReminderEmail } from 'app/shared/models';
import {
  CompanyProductDefinition,
  EmailListPreview, GroupCategoriesHierarchy, GroupResultInfo, ProductIndex, UserView,
  WizardSelectionGroup
} from '.';
import { ModuleView } from '../product-wizard/survey/module-selection/models';
import { ValidationData } from '../product-wizard/survey/module-selection/models/survey-wizard-question.model';
import { SmsList } from './sms.model';

export enum WizardTypes {
  survey = 'survey',
  followup = 'followup'
}

export type WizardContexts = SurveyWizardContext | FollowupWizardContext;

export interface ProductWizardContext {
  activeStep: number;
  productId?: number;
  unsaved: boolean;
  saving: boolean;
  productDefinition: CompanyProductDefinition;
  currentVersion: string;
  pristine?: boolean;
}

export interface SurveyWizardContext extends ProductWizardContext {
  wizardType: WizardTypes.survey;
  modules: ModuleView[];
  allModules: ModuleView[];
  participants: ParticipantContext;
  schedule: ScheduleContext;
  communication: CommunicationContext;
  name: string;
  index?: IndexContext;
  surveyId?: number;
  moduleId?: number;
  wasDeactivated: boolean;
}

export interface FollowupWizardContext extends ProductWizardContext {
  groupResultInfo: GroupResultInfo;
  groupId: number;
  selectedCategory?: RecommendationCategory;
  selectedSubCategory?: RecommendationSubCategory;
  selectedRecommendation: Recommendation;
  wizardType: WizardTypes.followup;
  nows: FollowupNow[];
  goals: FollowupGoal[];
  actions: FollowupAction[];
  recommendations: Recommendation[];
  suggestions?: string;
  getSuggestions?: boolean;
  byPassGuard?: boolean;
}

export interface ScheduleContext {
  startDate: Date;
  endDate: Date;
  reportDate: Date;
  directActivation: boolean;
  reminderEmails: ReminderEmail[];
}

export interface CommunicationContext {
  activationEmail: EmailListPreview;
  activationSms: SmsList;
  autoReport: boolean;
  autoReportMode: AutoReportMode;
  communicationEnabled: boolean;
  communicationMethod: string;
  hasPinGroups: boolean;
  pinCodeEmail: EmailListPreview;
  pinCodeSms: SmsList;
  previewLanguage: Language;
  reminderEmail?: EmailListPreview;
  reportEmail: EmailListPreview;
  welcomeSms: SmsList;
}

export interface IndexContext {
  indexInfo: ProductIndex[];
  indexValidation: ValidationData[];
  indexes: number[];
}
export interface ParticipantContext {
  selectedHierarchy: GroupCategoriesHierarchy;
  groups: WizardSelectionGroup[];
  participantCount: number;
  phoneNumberParticipantCount: number;
  nonPinCodeParticipantCount: number;
  emailParticipantCount: number;
  perspectivesRespondentsCount?: number;
  perspectivesFocusUsersCount?: number;
}

export interface FollowupAction {
  id: number;
  tempId?: string; // to enable editing for not saved items (without proper id)
  productId: number;
  title?: string;
  description?: string;
  deadline?: Date;
  status: ActionStatus;
  responsibleUsers?: UserView[];
  responsibleUsersNames?: string;
  recommendationId?: number;
  targetUserIds?: number[];
  targetGroupIds?: number[];
  edit: boolean;
}

export enum ActionStatus {
  workinprogress = 'WorkInProgress',
  completed = 'Completed',
  cancelled = 'Cancelled'
}

export interface FollowupActionAdd {
  title?: string;
  description?: string;
  deadline?: Date;
  productId?: number;
  responsibleUserIds?: number[];
  recommendationId?: number;
  targetUserIds?: number[];
  targetGroupIds?: number[];
}
export interface FollowupActionEdit {
  id: number;
  title?: string;
  description?: string;
  deadline?: Date;
  responsibleUserIds?: number[];
  recommendationId?: number;
  targetUserIds?: number[];
  targetGroupIds?: number[];
  status: string;
}

export interface FollowupNow {
  description: string;
  edit: boolean;
  tempId: string;
}

export interface FollowupGoal {
  description: string;
  edit: boolean;
  tempId: string;
}
export interface Recommendation {
  id?: number;
  actionAreaId?: number,
  categoryKey?: string;
  category?: string;
  subCategoryKey?: string;
  subCategory?: string;
  name?: string;
  shortText?: string;
  text?: string;
  key?: string;
}

export interface ActionArea {
  id: number;
  key: string;
  name: string;
}
export interface RecommendationCategory {
  key: string;
  name: string;
}

export interface RecommendationSubCategory {
  categoryKey?: string;
  subCategoryKey?: string;
  name?: string;
}

export function isSurveyWizardContext(context: WizardContexts): context is SurveyWizardContext {
  return context && 'modules' in context;
}
export function isFolloupWizardContext(context: WizardContexts): context is SurveyWizardContext {
  return context && 'recommendations' in context;
}
