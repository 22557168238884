import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/shared';
import { DeleteEmail, EditEmail, Email, EmailList, EmailListPreview, EmailLog, EmailType, RenderedEmail, SendGridEmailTemplate } from 'app/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  list(): Observable<EmailList[]> {
    return this._http
      .get<EmailList[]>(`${this._config.apiUrl}/api/emails`);
  }

  setIsActiveFlag(id: number, isActive: boolean): Observable<HttpResponse<boolean>> {
    return this._http
      .put<boolean>(`${this._config.apiUrl}/api/emails/${id}/active`, { isActive: isActive },
        { observe: 'response', responseType: 'json' });
  }

  delete(id: number): Observable<HttpResponse<DeleteEmail>> {
    return this._http.delete<DeleteEmail>(`${this._config.apiUrl}/api/email/${id}/delete`, { observe: 'response' });
  }

  hasRelatedTasksOrDrafts(id: number): Observable<boolean> {
    return this._http.get<boolean>(`${this._config.apiUrl}/api/email/${id}/related`);
  }

  getOLD(id: number, companyId: number, languageId: number): Observable<Email> {
    return this._http
      .get<Email>(`${this._config.apiUrl}/api/emails/${id}/company/${companyId}/language/${languageId}`);
  }

  // Fetch email, and all content, by Id
  /**
   *
   * @param {number} id Email id
   * @param {number} languageId Id of the language in which the variables should be rendered
   * @returns {Observable<Email>}
   */
  get(id: number, languageId: number): Observable<Email> {
    return this._http
      .get<Email>(`${this._config.apiUrl}/api/email/${id}`,
        { params: { languageId: languageId } });
  }

  getTypes(): Observable<EmailType[]> {
    return this._http
      .get<EmailType[]>(`${this._config.apiUrl}/api/emails/types`);
  }
  getAvailableTypes(companyId: number): Observable<EmailType[]> {
    return this._http
      .get<EmailType[]>(`${this._config.apiUrl}/api/companies/${companyId}/emails/availableTypes`);
  }

  listWithDefaultEmails(companyId: number): Observable<EmailList[]> {
    return this._http
      .get<EmailList[]>(`${this._config.apiUrl}/api/companies/${companyId}/emails`);
  }

  listWithDefaultActiveEmails(companyId: number): Observable<EmailList[]> {
    return this._http
      .get<EmailList[]>(`${this._config.apiUrl}/api/companies/${companyId}/emails/active`);
  }

  listWithDefaultEmailsWithContent(companyId: number): Observable<EmailListPreview[]> {
    return this._http
      .get<EmailListPreview[]>(`${this._config.apiUrl}/api/companies/${companyId}/emails/content`);
  }

  updateOLD(emailId: number, email: EditEmail): Observable<HttpResponse<any>> {
    return this._http.put(`${this._config.apiUrl}/api/emails/${emailId}`,
      email, { observe: 'response', responseType: 'text' });
  }

  update(emailId: number, email: EditEmail): Observable<HttpResponse<unknown>> {
    return this._http.put(`${this._config.apiUrl}/api/email/${emailId}`,
      email, { observe: 'response', responseType: 'text' });
  }

  create(email: EditEmail): Observable<number> {
    return this._http.post<number>(`${this._config.apiUrl}/api/email`, email);
  }

  /**
  * @deprecated The method is using the legacy email controller
  * endpoint, and should not be used
  */
  create_(email: EditEmail): Observable<number> {
    return this._http.post<number>(`${this._config.apiUrl}/api/emails`, email);
  }

  sendMass(emailId: number, companyId: number, groupId: number, userIds: number[], data?: { surveyId?: number }): Observable<void> {
    let model: {
      companyId: number,
      userGroupId: number,
      emailId: number,
      userIds: number[],
      surveyId?: number
    };
    model = { companyId: companyId, userGroupId: groupId, emailId: emailId, userIds: userIds };
    if (data) {
      model = { ...data, ...model };
    }
    return this._http
      .post<void>(`${this._config.apiUrl}/api/emails/sendmass`, model);
  }

  send(emailId: number, userId: number, data?: { surveyId?: number }): Observable<boolean> {
    let model: { userId: number, emailId: number, surveyId?: number };
    model = { userId: userId, emailId: emailId };
    if (data) {
      model = { ...data, ...model };
    }
    return this._http
      .post<boolean>(`${this._config.apiUrl}/api/emails/send`, model);
  }

  renderedEmail(email: number | EditEmail, languageId: number, companyId?: number, useReminderPrefix: boolean = false): Observable<RenderedEmail> {
    const c = companyId != null ? companyId.toString() : '';
    if (typeof email === 'number') {
      return this._http
        .get<RenderedEmail>(`${this._config.apiUrl}/api/emails/${email}/rendered/${languageId}`, { params: { 'companyId': c, 'useReminderPrefix': useReminderPrefix } });
    } else {
      return this._http
        .post<RenderedEmail>(`${this._config.apiUrl}/api/emails/rendered/${languageId}`, email, { params: { 'companyId': c } });
    }
  }

  getTemplates(): Observable<SendGridEmailTemplate[]> {
    return this._http
      .get<SendGridEmailTemplate[]>(`${this._config.apiUrl}/api/emails/templates`);
  }

  getTemplateSections(templateId: string): Observable<string[]> {
    return this._http
      .get<string[]>(`${this._config.apiUrl}/api/emails/templates/${templateId}/sections`);
  }

  translateEmail(emailId: number, textXml: string): Observable<void> {
    return this._http
      .post<void>(`${this._config.apiUrl}/api/emails/${emailId}/translations`, { xmlData: textXml });
  }

  setIsActiveFlagAdmin(id: number, isActive: boolean, companyId: number): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/emails/${id}/active`, { isActive: isActive, companyId: companyId },
        { observe: 'response', responseType: 'text' });
  }
  getFailedEmails(taskId: number, companyId: number): Observable<EmailLog[]> {
    return this._http
      .get<EmailLog[]>(`${this._config.apiUrl}/api/companies/${companyId}/emails/failed`, { params: { 'taskId': taskId } });
  }
}
