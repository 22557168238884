import { ContentDetailed, ContentDetailedCreate } from 'app/shared/models';
import { MakeOptional } from '../utils';

export interface Email {
  id: number;
  bodyContent: ContentDetailed[];
  bodyModuleId: number;
  companyId: number;
  typeId: number;
  name: ContentDetailed;
  subject: ContentDetailed;
  subType: SubCommunicationType;
  senderName: string;
  senderAddress: string;
  templateId: string;
  permittedLanguages: number[];
  masterLanguageId: number;
}

export type EditEmail = MakeOptional<Omit<Email, 'bodyContent' | 'subject' | 'name'>, 'id' | 'companyId'>
  & { bodyContent: ContentDetailedCreate[], subject: ContentDetailedCreate, name: ContentDetailedCreate };

export type EmailList = Omit<Email,
  | 'bodyContent'
  | 'masterLanguageId'
  | 'subject'
  | 'senderName'
  | 'senderAddress'
  | 'name'>
  & {
    createdById: number;
    createTime: Date;
    typeName: string;
    isActive: boolean;
    isCore: boolean;
    lastModifiedTime: Date;
    lastModifiedBy: number;
    name: string;
    inUse: boolean;
    pending?: boolean;
  }

export interface EmailListPreview extends EmailList {
  body: string;
  sender: EmailAddress;
  subject: string;
}

export interface EmailType {
  id: number;
  name: string;
}

export interface EmailTranslation {
  languageId: number;
  name: string;
  subject: string;
  bodyContents: EmailBodyContent[];
}

export interface EmailBodyContent {
  id: number;
  body: string;
}

export interface EmailAddress {
  address: string;
  displayName: string;
  host: string;
  user: string;
}

export interface RenderedEmail {
  id: number;
  sender: EmailAddress;
  subject: string;
  body: string;
  languageId: number;
  companyId: number;
}

export interface CreateSurveyReminder {
  companyId: number;
  emailId: number;
  sendDate: Date;
  surveyId: number;
  pinCodeEmailId: number;
  smsId: number;
  pinCodeSmsId: number;
}

export interface DeleteEmail {
  deleted: boolean;
  inUse?: boolean;
}

export enum CommunicationTypes {
  SurveyActivation = 'SurveyActivation',
  Welcome = 'Welcome',
  ForgottenPassword = 'ForgottenPassword',
  PinCode = 'PinCode',
  Reminders = 'Reminders',
  Report = 'Report'
}

export enum CommunicationGroupTypes {
  Email = 'email',
  SMS = 'sms',
  Report = 'report',
  EmailPinCode = 'pin-email',
  SMSPinCode = 'pin-sms',
  SMSReport = 'report-sms'
}

export enum SubCommunicationType {
  Standard = 'Standard',
  ThreeSixty = 'ThreeSixty',
  Relation = 'Relation'
}

export interface SendGridEmailTemplate {
  templateId: string;
  name: string;
}

export interface TemplateList {
  id: number;
  name: string;
  typeName: string;
  companyId: number;
}

export interface EmailLog {
  email: string;
  type: string;
  reason: string;
}

export interface SmsLog {
  phoneNumber: string;
  type: string;
  reason: string;
}

export type CommunicationMediaType = 'email' | 'sms';
