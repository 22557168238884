import { ContentDetailed, ContentItem } from 'app/shared/models';
import { InfoPopup } from '../info-popup.model';
import { IndexHeaderData } from '../result-pages/index-header.model';
import { DisplayType } from './menu-item.model';

export interface StyleContainer {
  width?: string;
  height?: string;
  margin?: string;
  gap?: string;
}

export interface ColumnContainer {
  style?: StyleContainer;
  isExclusiveRender: boolean;
  reportPageItemType: ReportPageItemType;
  matrix?: boolean;
  graph?: boolean;
}
export interface RowContainer {
  columns: ColumnContainer[];
}
export interface LayoutContainer {
  rows: RowContainer[];
}
export interface ReportDefinitionSimple {
  id: number;
  name: string;
  reportKey: string;
  companyId: number;
  parameterKeys?: string[];
  permission?: string;
}

export interface ReportPage {
  name: ContentItem[];
  reportGroupSelectorParameter: string;
  items: ReportPageItem[];
  indexHeader: IndexHeaderData;
  collapseQuestions: boolean;
  layoutContainer: string;
}
export interface ReportPageItem<T = any> {
  id: number;
  name: string;
  pageItemType: ReportPageItemType;
  model: T;
  dataSourceParameterValues: string[];
  helpTexts: InfoPopup[];
}

export interface ReportDefinitionDto {
  id?: number;
  companyId: number;
  reportKey: string;
  templateName: string;
  name: ContentDetailed;
  description: ContentDetailed;
  reportPages: ReportPageDto[];
  parameterSelectors: ReportParameterSelectorDto[];
}

export interface ReportParameterSelectorDto {
  parameterKey: string;
  label?: ContentItem[];
  description?: ContentItem[];
  type: string;
  source: string;
  path?: string;
  hidden?: boolean;
}

export interface SelectableReportDefinition extends ReportDefinitionSimple {
  checked: boolean;
}

export interface ReportStructureDto {
  companyId: number;
  reportPages: ReportPageDto[];
  dataSources: DataSourceDto[];
}

export interface ReportPageDto {
  name: ContentItem[];
  key: string;
  groupName?: string;
  enabled?: boolean;
  reportGroupSelectorSettings?: any;
  layout?: string;
  settings: string;
  pageType?: string;
  condition?: ConditionDto;
  parameter?: string;
  displayType: DisplayType;
  dataConditions?: DataConditionDto[];
  dataSourceNames?: string[];
  meta: ReportPageMetaDto[] | null;
  children?: ReportPageDto[];
  reportPageItems?: ReportPageItemDto[];
}
export interface ReportPageMetaDto {
  key: string;
  value: string;
}
export interface ReportPageItemDto {
  name?: string;
  key?: string;
  enabled: boolean;
  order: number;
  reportPageItemType: ReportPageItemType;
  condition?: ConditionDto;
  dataSourceNames: string[];
  helpTextContentNames: string[];
  settings: ReportPageItemSettings;
}

export interface ReportPageItemSettings {
  indexes?: (string | {
    Key: string,
    SubResultKey: string
  })[];
  indexKey?: string;
  optionGrade?: string;
  expandAllCombinations?: boolean;
  excludedQuestionKeys?: string[];
  comparisonIndexResultValueParam?: string;
}

export interface ReportPageParameterDto {
  name: string;
  dataSourceName: string;
  dataConditions: DataConditionDto[];
}
export interface ConditionDto {
  id?: number;
  name?: string;
  leftSource: string;
  leftSourceId?: number;
  leftField?: string;
  leftDataSourceName?: string;
  leftValue?: string;
  operator: string;
  logic?: string;
  rightSource: string;
  rightSourceId?: number;
  rightField?: string;
  rightDataSourceName?: string;
  rightValue?: string;
  conditions?: ConditionDto[];
}

export interface DataConditionGroup {
  name?: string;
  groupField?: string;
  labels?: string;
  dataLabel?: string;
  dataFields?: string;
  orderBy: string;
  condition: string;
  groups: DataConditionGroup[];
}

export interface DataConditionDto {
  name: string;
  dataSourceType: string;
  groups: DataConditionGroup[];
  condition?: string;
}

export interface DataSourceDto {
  name: string;
  items: DataSourceItemDto[];
  take: number;
}
export interface DataSourceItemDto {
  parameterKeys: string[];
  type: string;
}
export interface ParameterDto {
  key: string;
  initialization: string;
  entityType: string;
  description: string;
  expiration: string;
  expression?: string;
  allowedValues?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export enum ReportPageItemType {
  ENps = 'ENps',
  EngagementMatrix = 'EngagementMatrix',
  RecommendedFocusArea = 'RecommendedFocusArea',
  ENpsDistribution = 'ENpsDistribution',
  ENpsAmbassadors = 'ENpsAmbassadors',
  Information = 'Information',
  ENpsFreetext = 'ENpsFreetext',
  QuestionResults = 'QuestionResults',
  QuestionsWithoutIndex = 'QuestionsWithoutIndex',
  IndexWithComparison = 'IndexWithComparison',
  ResultList = 'ResultList',
  IndexDescription = 'IndexDescription',
  IndexSelector = 'IndexSelector',
  IndexGroupDistributions = 'IndexGroupDistributions',
  BarInsight = 'BarInsight',
  LatestSummary = 'LatestSummary',
  LatestSummaryHeader = 'LatestSummaryHeader',
  PreviousOverviewResults = 'PreviousOverviewResults',
  ParameterSelector = 'ParameterSelector',
  FollowUp = 'FollowUp',
  Recommendations = 'Recommendations',
  RecommendationsFollowup = 'RecommendationsFollowup',
  FollowUpsOverview = 'FollowUpsOverview',
  FollowUpsOverviewTeams = 'FollowUpsOverviewTeams',
  FollowUpsOverviewSummations = 'FollowUpsOverviewSummations',
  ActionPlanList = 'ActionPlanList',
  RecommendationsFollowupSummations = 'RecommendationsFollowupSummations',
  FilterBar = 'FilterBar',
  Description = 'Description',
  ActionPlansOverview = 'ActionPlansOverview',
  RecommendationsStats = 'RecommendationsStats',
  ActionPlanSummaryList = 'ActionPlanSummaryList',
  ActionPlansAreas = 'ActionPlansAreas',
  TopBottomQuestions = 'TopBottomQuestions',
  Perspectives = 'Perspectives',
  PerspectivesFreetext = 'PerspectivesFreetext',
  Act = 'Act',
  ReportSummaryHeader = 'ReportSummaryHeader',
  Nps = 'Nps',
  SummaryFreeText = 'SummaryFreeText',
  SurveyInsight = 'SurveyInsight',
  QuestionTonalityResults = 'QuestionTonalityResults',
  FreetextTonalityResponses = 'FreetextTonalityResponses',
  NpsCorrelation = 'NpsCorrelation',
  TrendGraph = 'TrendGraph',
  CurrentSurveyStatus = 'CurrentSurveyStatus',
  CurrentSurveyStats = 'CurrentSurveyStats',
  IndexValue = 'IndexValue',
  IndexValueMatrix = 'IndexValueMatrix',
  IndexValueMatrixGraph = 'IndexValueMatrixGraph'
}

